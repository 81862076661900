export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: '300',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    ':hover': {
      backgroundColor: 'secondary',
      color: 'dark'
    }
  },
  secondary: {
    backgroundColor: 'white',
    color: 'text',
    padding: '1rem 2.5rem',
    border: 'none',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
