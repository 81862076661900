export default {
  text: '#000000',
  primary: '#000000',
  secondary: '#DEB03C',
  tertiary: '#F7F7F7',
  background: 'transparent',
  backgroundSecondary: 'transparent',
  light: '#b3afaf',
  dark: '#3b3a3a'
}
