const variantName = 'menuV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      justifyContent: 'center',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: ['2rem 1rem']
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      margin: '1rem 1rem 2rem',
      cursor: 'pointer',
      justifyContent: 'center',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '50%' // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      marginBottom: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px'
      }
    },

    sectionTab: {
      margin: '0.25rem',
      minWidth: 'max-content',
      maxWidth: 'max-content',
      width: '100%',
      border: '1px solid',
      borderCoor: 'dark',
      padding: '0.75rem 1rem 1rem',
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'secondary',
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: '1.5rem',
      '&:hover': {
        backgroundColor: 'dark',
        color: '#fff',
        transition: 'all 0.5s'
      }
    },

    sectionTabActive: {
      variant: `${variantName}.sectionTab`,
      backgroundColor: 'primary',
      color: 'light'
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainerWrapper: {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      maxWidth: '1200px',
      margin: ' 0 auto'
    },
    menuContainer: { paddingBottom: '2rem' },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))'],
      backgroundColor: 'secondary',
      margin: ['1rem 0', '0.5rem'],
      overflow: 'hidden',
      ':hover .cellImageContainer ': {
        transform: 'scale(1.2)'
      }
    },

    menuCell: {
      position: 'relative',
      flexWrap: 'wrap',
      height: '100%',
      backgroundColor: 'background'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
      transition: 'all ease-in-out 0.6s',
      filter: 'brightness(0.9)'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellName: {
      fontSize: ['1.75rem', '3rem'],
      fontWeight: 'normal',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, -50%)',
      fontFamily: 'display',
      textAlign: 'center',
      color: 'white'
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      fontSize: ['2rem', '', '3rem'],
      textAlign: 'center',
      color: 'white',
      fontWeight: 'bold',
      borderBottom: 'solid 2px',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      textAlign: 'left',
      // textTransform: 'uppercase',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1rem 1.5rem'],
      fontFamily: 'heading'
    },
    menuSectionDescription: {
      textAlign: 'center',
      fontSize: 4,
      marginBottom: '2rem',
      color: 'white'
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0,

      '.menuItemImgDefaultContainer': {
        display: 'none!important'
      }
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem', '', '0 1rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))'],
      margin: '0rem 0rem 2rem'
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
      flexDirection: 'column'
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['none', 'none'],
      maxHeight: ['none', '', '', '474px'],
      marginBottom: '1rem'
      //   padding: '0 0.75rem 0 0',
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      //   width: ['130px', '150px', '175px'],
      width: '100%',
      cursor: 'pointer',
      borderRadius: '10px'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    '.menuItemNamePriceContainer': {
      padding: '0 .5rem'
    },

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      // margin: '1rem 0rem',
      position: 'relative'
    },
    menuItemName: {
      fontSize: ['1.1rem', '1.25rem', '', '1.25rem'],
      fontWeight: 'normal',
      // marginBottom: '1rem',
      paddingRight: '8px',
      color: 'white',
      opacity: '0.9',
      textTransform: 'uppercase',
      // fontWeight: 'bold',
      margin: '0rem 0rem 0.25rem',
      fontFamily: 'body',
      fontWeight: 'bold'
    },
    menuItemDescription: {
      fontSize: '0.85rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      fontSize: '1rem',
      fontWeight: '300',
      opacity: '0.6',
      color: 'white'
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },

    variantsContainer: {},

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex'
    },

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px'
    },
    menuItemPriceVariants: {
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
      marginBottom: '1rem',
      width: '100%',
      display: 'none!important'
    },

    menuDropdownBtn: {
      variant: 'buttons.primary'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      opacity: '0.8',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center'
      }
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    }
  }
}
