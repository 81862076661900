export default {
  heading: 'Special Elite, cursive',
  body: 'Enriqueta, sans-serif',
  monospace: 'Special Elite, monospace',
  display: 'Special Elite, serif',
  display2: 'Special Elite, sans-serif',
  display3: 'Special Elite',
  googleFonts: ['Enriqueta', 'Special Elite'],
  customFamilies: [],
  customUrls: []
}
